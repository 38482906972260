<template lang="html">
    <vx-card>
      <div id="user-content"  slot="no-body" class="tabs-container px-6 pt-6" >
        <vs-tabs v-model="activeTab" v-if="user_data" class="tab-action-btn-fill-conatiner">
          <vs-tab :label="$t('user.account')" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <account-tab :data="user_data" :loadDataParent="loadData" class="mt-4" />
            </div>
          </vs-tab>

          <vs-tab :label="$t('common.info')" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <info-tab :data="user_data" :loadDataParent="loadData" class="mt-4" />
            </div>
          </vs-tab>

        </vs-tabs>
      </div>

    </vx-card>
</template>

<script>
import i18n from '@/i18n/i18n'
import AccountTab     from "./AccountTab.vue"
import InfoTab     from "./InfoTab.vue"

export default {
    components: {
        AccountTab,
        InfoTab
    },
    data:()=>({
        activeTab: 0,
        user_data: null
    }),
    methods:{
      loadData() {
            var self = this
            var activeUser = this.$store.state.AppActiveUser;

            this.$vs.loading({
                container: '#user-content',
                scale: 0.5
            });

            this.$store.dispatch('httpRequest', {method: 'get', url:`user/${activeUser.id}`}).then((data) => {
                self.$vs.loading.close('#user-content > .con-vs-loading')
                self.user_data = data;

            }).catch(error => {
                self.$vs.loading.close('#user-content > .con-vs-loading')
                this.$vs.notify({
                    title: i18n.t('dialog.error'),
                    text: error.message,
                    color: 'danger'
                })
            });

        }
    },
    mounted() {
        this.loadData();
    }

}
</script>
<style scoped>
.tabs-container{
  min-height: 400px;
}
</style>