<template lang="html">
    <vx-card no-shadow>
        <!-- Content Row -->
        <div class="vx-row">
            
            <!-- Address Col -->
            <div class="vx-col w-full md:w-1/2">

                <!-- Col Header -->
                <div class="flex items-end md:mt-0 mt-base">
                    <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
                    <span class="leading-none font-medium">{{$t('common.address')}}</span>
                </div>

                <!-- Col Content -->
                <div>
                    <vs-input class="w-full mt-4" :label="$t('address.line')" v-model="address.line1" 
                    v-validate="'required'" name="addd_line_1" :data-vv-as="$t('address.line')" />
                    <span class="text-danger text-sm"  v-show="errors.has('addd_line_1')">{{ errors.first('addd_line_1') }}</span>

                    <vs-input class="w-full mt-4" :label="$t('address.zipcode')" v-model="address.zipcode" 
                        v-validate="'required'" name="post_code"  :data-vv-as="$t('address.zipcode')"  />
                    <span class="text-danger text-sm"  v-show="errors.has('post_code')">{{ errors.first('post_code') }}</span>

                    <vs-input class="w-full mt-4" :label="$t('address.city')"  v-model="address.city" 
                    v-validate="'required'" name="city"  :data-vv-as="$t('address.city')" />
                    <span class="text-danger text-sm"  v-show="errors.has('city')">{{ errors.first('city') }}</span>

                    <vs-input class="w-full mt-4" :label="$t('address.state')"  v-model="address.state" 
                        v-validate="'required'" name="state" :data-vv-as="$t('address.state')" />
                    <span class="text-danger text-sm"  v-show="errors.has('state')">{{ errors.first('state') }}</span>

                    <vs-input class="w-full mt-4" :label="$t('address.country')" v-model="address.country" 
                        v-validate="'required'" name="country"  :data-vv-as="$t('address.country')" />
                    <span class="text-danger text-sm"  v-show="errors.has('country')">{{ errors.first('country') }}</span>

                </div>
            </div>

            <div class="vx-col w-full md:w-1/2">

                <!-- Col Header -->
                <div class="flex items-end">
                    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
                    <span class="leading-none font-medium">{{$t('user.personal')}}</span>
                </div>

                <!-- Col Content -->
                <div>

                    <!-- DOB -->
                    <div class="mt-4">
                        <label class="text-sm">{{$t('user.dob')}}</label>
                        <!--<flat-pickr v-model="data_local.dob" :config="{ dateFormat: 'd F Y', maxDate: new Date() }" class="w-full" v-validate="''" name="dob" />-->
                        <datepicker v-model="data_local.birthday" :language="languages[currentLanguage]" format="d MMMM yyyy"></datepicker>
                        <span class="text-danger text-sm"  v-show="errors.has('dob')">{{ errors.first('dob') }}</span>
                    </div>

                    <vs-input class="w-full mt-4" :label="$t('common.phone')" v-model="data_local.phone" v-mask="['(##) ####-####', '(##) #####-####']" />
                    <span class="text-danger text-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>

                </div>
            </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end">
                    <vs-button id="button-with-loading" class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{ $t('actions.save') }}</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetData">{{ $t('actions.reset') }}</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import i18n from '@/i18n/i18n'
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import moment from 'moment'

export default {
    components: {
        vSelect,
        Datepicker,
    },
    props: ['data', 'loadDataParent'],
    data() {
        return {
            language: "en",
            languages: lang,
            address: {},
            data_local: {},
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
        currentLanguage() {
            var current = localStorage.getItem('language');
            return (current == 'pt_BR') ? 'ptBR' : localStorage.getItem('language');
        }
    },
    methods: {
        resetData() {
            this.data_local = JSON.parse(JSON.stringify(this.data));
            this.address = JSON.parse(JSON.stringify(this.data.address));
            this.data_local.birthday = new Date(moment(this.data_local.birthday));
        },
        saveChanges() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    var self = this;
                    var payload = JSON.parse(JSON.stringify(this.data_local));

                    payload.birthday = moment(this.data_local.birthday).format('YYYY-MM-DD');
                    payload.address = this.address;

                    this.$vs.loading({
                        container: '#button-with-loading',
                        scale: 0.5
                    });
                    
                    this.$store.dispatch('httpRequest', {method: 'post', url: 'user/edit', data: payload}).then((data) => {
                        self.$vs.loading.close('#button-with-loading > .con-vs-loading')
                        self.loadDataParent();
                        this.$vs.notify({
                            title: i18n.t('dialog.success'),
                            text: data.message,
                            color: 'success'
                        })
                    }).catch(error => {
                        self.$vs.loading.close('#button-with-loading > .con-vs-loading')
                        this.$vs.notify({
                            title: i18n.t('dialog.error'),
                            text: error.message,
                            color: 'danger'
                        })
                    });
                    
                }
            });
        },
    },
    mounted() {
        this.data_local = JSON.parse(JSON.stringify(this.data));
        this.data_local.birthday = new Date(moment(this.data_local.birthday));
        this.address = JSON.parse(JSON.stringify(this.data.address));
    }
}
</script>

                