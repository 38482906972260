<template lang="html">
    <vx-card no-shadow>
        <!-- Avatar Row -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="flex items-start flex-col sm:flex-row">
                    <vs-avatar :src="avatar" size="80px" class="mr-4" />
                    <div>
                        <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ data.name  }}</p>
                        <input type="file" class="hidden" ref="update_avatar_input" @change="uploadImage" accept="image/*">

                        <vs-button type="border" class="mr-4" @click="$refs.update_avatar_input.click()">{{$t('user.actions.changeAvatar')}}</vs-button>
                        <vs-button type="border" color="danger" @click="removeAvatar">{{$t('user.actions.removeAvatar')}}</vs-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content Row -->
        <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
                <vs-input class="w-full mt-4" :label="$t('common.name')" v-model="data_local.name" 
                    v-validate="'required'" name="name" :data-vv-as="$t('common.name')" />
                <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>

                <vs-input class="w-full mt-4" label="Email" v-model="data_local.login" type="email" 
                v-validate="'required|email'" name="email" disabled/>
                <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full">
                <vs-input class="w-full mt-4" :label="$t('common.company')" v-model="data_local.company" v-validate="''" name="company" />
                <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span>
            </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end">
                    <vs-button id="button-with-loading" class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{ $t('actions.save') }}</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetData">{{ $t('actions.reset') }}</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import i18n from '@/i18n/i18n'

export default {
  props: ['data', 'loadDataParent'],
  data() {
    return {
        fileUrl: '',
        avatar: '',
        data_local: JSON.parse(JSON.stringify(this.data)),
    }
  },
  computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        resetData() {
            this.data_local = JSON.parse(JSON.stringify(this.data));
            this.avatar = this.data_local.picture;
            this.$refs.update_avatar_input.value = null;
        },
        saveChanges() {
            this.$validator.validateAll().then(result => {
                if(result) {
                    var self = this;
                    var payload = JSON.parse(JSON.stringify(this.data_local));

                    this.$vs.loading({
                        container: '#button-with-loading',
                        scale: 0.5
                    });

                    payload.picture = this.avatar;
                    
                    this.$store.dispatch('httpRequest', {method: 'post', url: 'user/edit', data: payload}).then((data) => {
                        self.$vs.loading.close('#button-with-loading > .con-vs-loading')
                        self.loadDataParent();
                        this.$vs.notify({
                            title: i18n.t('dialog.success'),
                            text: data.message,
                            color: 'success'
                        })

                        self.$store.commit("UPDATE_USER_INFO", {
                            name: self.data_local.name,
                            picture: self.avatar
                        })

                    }).catch(error => {
                        self.$vs.loading.close('#button-with-loading > .con-vs-loading')
                        this.$vs.notify({
                            title: i18n.t('dialog.error'),
                            text: error.message,
                            color: 'danger'
                        })
                    });
                    
                }
            });
        },
        uploadImage(e){
            const image = e.target.files[0];

        if (image.size > 1024 * 1024) {
            e.preventDefault();
            this.$refs.update_avatar_input.value = null;
            this.$vs.dialog({
                color:'danger',
                title: i18n.t('dialog.error'),
                text: i18n.t('user.messages.error.maxImageSize'),
                acceptText:i18n.t('dialog.accept')
            })
            return;
        }

            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.previewImage = e.target.result;
                //this.data_local.picture = this.previewImage;
                this.avatar = this.previewImage;
            };
        },
        removeAvatar(){
            //this.data_local.picture = '';
            this.avatar = '';
            this.$refs.update_avatar_input.value = null;
        }
    },
    mounted() {
        this.avatar = this.data.picture;
    }
}
</script>

                